<template>
  <div>
    <create
      :is-add-new-page-sidebar-active.sync="isShowFormAdd"
      :is-loading="isLoading"
      :type-form="typeForm"
      :data="tagData"
      @onSubmit="onSubmit"
    />
    <b-overlay
      id="overlay-background"
      spinner-variant="primary"
      variant="transparent"
      opacity="1.00"
      blur="0.5em"
      rounded="sm"
      :show="isLoading"
      import-excel
    >
      <b-card no-body class="knowledge-base-bg text-center">
        <b-card-body class="card-body">
          <b-row v-if="tagData">
            <b-col cols="12" md="4">
              <b-form-group label="Nama" label-for="name">
                <b-form-input
                  id="name"
                  v-model="tagData.name"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Created At" label-for="created_at">
                <b-form-datepicker
                  id="created_at"
                  v-model="tagData.created_at"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Keterangan" label-for="note">
                <b-form-textarea
                  id="note"
                  v-model="tagData.note"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-card>
      <own-table
        ref="productTable"
        name-table="produk"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        :export-excel="false"
        sort-by="kd_produk"
        sort-direction="asc"
        :extend-query="`&tags=${tagData ? tagData.name : 'null'}`"
        import-excel
        @searchFieldOnChange="searchOnChange"
        @evt-add="
          () => {
            isShowFormAdd = true
            typeForm = 'add'
          }
        "
      >
        <template #import-excel-template>
          <b-button class="btn btn-warning" @click="importExcel()">
            Import Excel
          </b-button>
        </template>
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(is_active)="data">
          <b-badge :variant="statusVariant(data.data.item.is_active)">
            <span>{{
              data.data.item.is_active === 1 ? 'Aktif' : 'Tidak Aktif'
            }}</span>
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(start_date)="data">
          <span>{{ formatDate(data.data.item.start_date) }}</span>
        </template>
        <template #cell(expire_date)="data">
          <!-- <span>{{ moment(data.data.item.expire_date).format('DD-MM-YYYY') }}</span> -->
          <span>{{ formatDate(data.data.item.expire_date) }}</span>
        </template>
        <template #cell(value)="data">
          <span>{{
            data.data.item.type === 'fixed' ||
            data.data.item.type === 'shipping'
              ? formatCurrency(data.data.item.value)
              : `${data.data.item.value}%`
          }}</span>
        </template>
        <template #cell(min_transaction_value)="data">
          <span>{{
            formatCurrency(data.data.item.min_transaction_value)
          }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="onDelete(data.data.item.kd_produk)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
    <b-modal
      v-model="showModalImport"
      ok-only
      title="Import Excel"
      @ok="okImportHandler"
    >
      <validation-observer #default="{ handleSubmit }" ref="refImportObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <span
            >Use this
            <a :href="`${config.apiUrl}/public/file/tag_product_template.xls`"
              >Template</a
            ></span
          >
          <validation-provider
            #default="validationContext"
            name="File"
            rules="required"
          >
            <b-form-group>
              <b-form-file
                v-model="excelFile"
                :state="Boolean(excelFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="success" @click="ok()">
          <span>Upload</span>
          <b-spinner v-if="isLoadingImport" small class="ml-1" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCardText,
  BCardBody,
  BOverlay,
  BCard,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BFormGroup,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted, watch, onMounted } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import service from '@/components/Table/service'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import config from '@/config/config'
import Create from './components/CreateDetail'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    Create,
    BFormFile,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BCardBody,
    BOverlay,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
  },
  data() {
    return {
      warehouseOptions: [],
    }
  },
  methods: {
    warehouseOnSearch(search, loading) {
      this.warehouseFilter(search, loading, this)
    },
    warehouseFilter: _.debounce((search, loading, vm) => {
      loading(true)
      service
        .index({
          url: 'warehouse-commerce',
          query: `sort_by=nama_produk&direction=asc&q=${search}`,
        })
        .then(res => {
          vm.warehouseOptions = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  mounted() {
    service
      .index({
        url: 'warehouse-commerce',
        query: 'sort_by=created_at&direction=desc',
      })
      .then(res => {
        this.warehouseOptions = res.data.data
      })
  },
  setup(props, { root }) {
    const productTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Produk',
        key: 'kd_produk',
      },
      {
        label: 'Nama Produk',
        key: 'nama_produk',
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const tagData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      console.log(typeForm.value)
      if (typeForm.value === 'add') {
        service
          .store({ url: 'tag-product/add', data: formData })
          .then(res => {
            productTable.value.refetchData()
            isShowFormAdd.value = false
          })
          .catch(err => {
            console.log(err)
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Add Data',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        service
          .update({ url: 'tags', data: formData })
          .then(res => {
            productTable.value.refetchData()
            isShowFormAdd.value = false
          })
          .catch(err => {
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Edit Data',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      tagData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const setStatus = data => {
      const form = data
      form.is_active = data.is_active === 1 ? 0 : 1
      productTable.value.onEdit()
      productTable.value.submit(form, () => {})
    }

    const edit = data => {
      typeForm.value = 'edit'
      productTable.value.onEdit()
      tagData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = kdProduk => {
      isLoading.value = true
      service
        .store({
          data: { id: root.$route.params.id, kd_produk: kdProduk },
          url: 'tag-product/remove',
        })
        .then(res => {
          setTimeout(() => {
            productTable.value.refetchData()
          }, 500)
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const retrieve = () => {
      isLoading.value = true
      service
        .show({ id: root.$route.params.id, url: 'tags' })
        .then(res => {
          tagData.value = res.data
          setTimeout(() => {
            productTable.value.refetchData()
          }, 500)
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    onMounted(() => {
      retrieve()
    })
    // const formatDateTime = date => formatDate(date)

    const warehouseId = ref(null)
    const refImportObserver = ref(null)

    const excelFile = ref(null)
    const showModalImport = ref(false)

    const isLoadingImport = ref(false)

    const importExcel = () => {
      showModalImport.value = true
    }

    const okImportHandler = evt => {
      evt.preventDefault()
      refImportObserver.value.validate().then(ok => {
        if (ok) {
          isLoadingImport.value = true
          const formData = new FormData()
          // console.log(excelFile)
          formData.append('tag_id', root.$route.params.id)
          formData.append('uploaded_file', excelFile.value)
          service
            .store({ data: formData, url: 'tag-product/import-excel' })
            .then(res => {
              if (res.status === 200) {
                showModalImport.value = false
                retrieve()
                productTable.value.refetchData()
              }
            })
            .catch(err => {
              const element = Vue.component('description', {
                render: h => serializeErrorMessage(h, err),
              })
              const toast = createToastInterface({
                timeout: 6000,
              })
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Import Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  description: element,
                },
              })
            })
            .finally(() => {
              isLoadingImport.value = false
            })
        }
      })
    }

    return {
      columns,
      queryFilter,
      productTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      setStatus,
      edit,
      tagData,
      typeForm,
      onDelete,

      okImportHandler,
      importExcel,
      showModalImport,
      isLoadingImport,
      excelFile,
      warehouseId,
      config,
      refImportObserver,
    }
  },
}
</script>
